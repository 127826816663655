











import { Component, Vue, Ref } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
@Component({
  components: {
    pacienteAnotaciones: () =>
      import("@/views/pacientes/paciente-anotaciones.vue"),
    PacienteFichaOpciones: () =>
      import("@/views/pacientes/paciente-ficha-opciones.vue")
  }
})
export default class pacienteAnotacionesHandler extends Vue {
  public id_paciente!: number;
  public created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
  }
}
